import { render } from "./DashboardSpaceOverview.vue?vue&type=template&id=463b1d14"
import script from "./DashboardSpaceOverview.vue?vue&type=script&lang=js"
export * from "./DashboardSpaceOverview.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "463b1d14"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('463b1d14', script)) {
    api.reload('463b1d14', script)
  }
  
  module.hot.accept("./DashboardSpaceOverview.vue?vue&type=template&id=463b1d14", () => {
    api.rerender('463b1d14', render)
  })

}

script.__file = "src/components/dashboard/DashboardSpaceOverview.vue"

export default script